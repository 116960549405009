<template>
    <div>
        <DadosPrestadorDialog
            v-if="prestador"
            :visible="showPrestadorDialog"
            :objPrestador="prestador"
            :showDadosFaturamento="false"
            @openCloseDadosPrestador="showInformacoesPrestador"
        />

        <Card class="mt-5 no-border no-padding DefinirClinicastep0">
            <template #title>
                Definir clínica
                <TourComponent tourName="DefinirClinicaGuiaGestaoMedica" />
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <div class="field col-6 m-0 DefinirClinicastep1">
                        <label :class="{ 'p-error': v$.form.prestador.$invalid && submitted }" class="w-full" for="prestador">
                            Prestador <span class="p-error">*</span>
                            <a
                                :style="{ visibility: !form.prestador ? 'hidden' : 'unset' }"
                                href="javascript:void(0)"
                                style="float: right"
                                @click="showInformacoesPrestador(form.prestador)"
                            >
                                Clique aqui para ver informações da cliníca
                            </a>
                        </label>
                        <DropdownPrestadorCliente
                            id="prestador"
                            v-model="form.prestador"
                            :autoLoad="true"
                            :class="{ 'p-invalid': v$.form.prestador.$invalid && submitted }"
                            :filtrosExtras="filtrosExtrasPrestador"
                            :idCidade="formData.cidade?.id"
                            :idCliente="formData.funcionarios?.map((e) => e.customerId)"
                        />
                        <small v-if="v$.form.prestador.$invalid && submitted" class="p-error">
                            {{ v$.form.prestador.required.$message }}
                        </small>
                    </div>
                    <div class="field col-3 m-0 DefinirClinicastep2">
                        <label :class="{ 'p-error': v$.form.intervaloSugerido.$invalid && submitted }" for="intervaloSugerido">
                            Intervalo sugerido
                            <i v-tooltip.top="'Data inicial e final sugeridas para agendamento'" class="pi pi-info-circle mr-1"></i>
                            <span class="p-error">*</span>
                        </label>
                        <AppInputCalendarioRanged
                            id="intervaloSugerido"
                            ref="inputCalendario"
                            v-model="form.intervaloSugerido"
                            :class="{ 'p-invalid': v$.form.intervaloSugerido.$invalid && submitted }"
                            :disabled="!form.prestador"
                            :disabledDays="disabledDays"
                            :idCidade="form.cidade?.id"
                            :idPrestador="form.prestador?.id"
                            :maxDate="intervaloMaxDate"
                            :minDate="intervaloMinDate"
                            :periodo="form.periodo"
                            :required="true"
                            :submitted="submitted"
                            @onGetPeriodoManhaVisible="setPeriodoManhaVisible"
                        />
                        <small
                            v-if="v$.form.intervaloSugerido.$invalid && submitted && form.intervaloSugerido && form.intervaloSugerido[1] === null"
                            class="p-error"
                        >
                            {{ v$.form.intervaloSugerido.required.$message }}
                        </small>
                    </div>
                    <div :class="exibirCampoHorario ? 'col-2' : 'col-3'" class="field m-0 DefinirClinicastep3">
                        <label :class="{ 'p-error': v$.form.periodo.$invalid && submitted }" for="periodo">
                            Período do dia <span class="p-error">*</span>
                        </label>
                        <Dropdown
                            id="periodo"
                            v-model="form.periodo"
                            :class="{ 'p-invalid': submitted && !form.periodo }"
                            :disabled="!form.intervaloSugerido || !form.intervaloSugerido[0] || periodoDisabled"
                            :options="periodos"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                        />
                        <small v-if="v$.form.periodo.$invalid && submitted" class="p-error">
                            {{ v$.form.periodo.required.$message }}
                        </small>
                    </div>
                    <div v-if="exibirCampoHorario" class="field col-1 m-0 DefinirClinicastep4">
                        <label :class="{ 'p-error': v$.form.horarioMarcado.$invalid && submitted }" for="horarioMarcado">
                            Horário <span class="p-error">*</span>
                        </label>
                        <InputMask
                            id="horarioMarcado"
                            v-model="form.horarioMarcado"
                            :class="{ 'p-invalid': v$.form.horarioMarcado.$invalid && submitted }"
                            mask="99:99"
                            required="true"
                            @blur="validarSePrestadorAtendePeriodoEHorario()"
                        />
                        <small v-if="horarioInvalido" class="p-error">Horário inválido.</small>
                        <small v-if="v$.form.horarioMarcado.$invalid && submitted" class="p-error">
                            {{ v$.form.horarioMarcado.required.$message }}
                        </small>
                    </div>
                    <div class="field col-6 m-0 DefinirClinicastep5">
                        <label>Cliente</label>
                        <InputText :value="this.formData.cliente.name" disabled />
                    </div>
                    <div class="field col-3 m-0 DefinirClinicastep6">
                        <label :class="{ 'p-error': v$.form.quemAgenda.$invalid && submitted }" for="quemAgenda">
                            Quem agenda <span class="p-error">*</span>
                        </label>
                        <Dropdown
                            id="quemAgenda"
                            v-model="form.quemAgenda"
                            :class="{ 'p-invalid': v$.form.quemAgenda.$invalid && submitted }"
                            :disabled="quemAgendaDisabled"
                            :options="agendaAvisa"
                            class="w-full"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione quem agenda"
                        />
                        <small v-if="v$.form.quemAgenda.$invalid && submitted" class="p-error">
                            {{ v$.form.quemAgenda.required.$message }}
                        </small>
                    </div>
                    <div class="field col-3 m-0 DefinirClinicastep7">
                        <label :class="{ 'p-error': v$.form.quemAvisa.$invalid && submitted }" for="quemAvisa">
                            Quem avisa <span class="p-error">*</span>
                        </label>
                        <Dropdown
                            id="quemAvisa"
                            v-model="form.quemAvisa"
                            :class="{ 'p-invalid': v$.form.quemAvisa.$invalid && submitted }"
                            :disabled="quemAvisaDisabled"
                            :options="agendaAvisa"
                            class="w-full"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione quem avisa"
                        />
                        <small v-if="v$.form.quemAvisa.$invalid && submitted" class="p-error">
                            {{ v$.form.quemAvisa.required.$message }}
                        </small>
                    </div>
                    <div class="field col-12 m-0 DefinirClinicastep8">
                        <label for="observação">Observação</label>
                        <Textarea id="observação" v-model="form.observacao" :autoResize="true" cols="20" required="true" rows="3" />
                    </div>
                    <div class="field col-5 m-0 field-checkbox DefinirClinicastep9">
                        <InputSwitch id="binary" v-model="form.resideForaDoPais" />
                        <label for="binary">Funcionário reside fora do país</label>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="grid grid-nogutter justify-content-between">
                    <Button :loading="isLoading" class="w-2 p-button-outlined DefinirClinicastep10" label="Voltar" @click="prevPage()" />
                    <Button :loading="isLoading || validandoDatas" class="w-2 DefinirClinicastep11" label="Avançar" @click="nextPage()" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import moment from 'moment';
import TipoExame from '../../../../enums/TipoExame';
import { uniq } from 'ramda';
import BaseService from '../../../../services/BaseService';
import { useVuelidate } from '@vuelidate/core';
import { between, helpers, required, requiredIf } from '@vuelidate/validators';
import DropdownPrestadorCliente from '../../../prestadores/components/DropdownPrestadorCliente';
import DadosPrestadorDialog from '../../../painel-agendamento/components/DadosPrestadorDialog';
import QuemAgendaAvisa from '../../../../enums/QuemAgendaAvisa';
import { limitarCaracteres } from '../../../../services/auth';
import { showWarning } from '../../../../utils/Toast';
import EnumPeriodo from '../../../../enums/EnumPeriodo';
import { obterDiaDaSemanaPorIndice } from '@/enums/EnumDiaDaSemana';
import TourComponent from '../../../../components/TourComponent';

const LIMITE_CARACTERES = 58;

export default {
    setup: () => ({ v$: useVuelidate() }),
    emits: ['prev-page', 'next-page', 'save-pre-solicitacao', 'save-agendamento'],
    props: {
        formData: Object,
        isLoading: Boolean
    },
    components: {
        DropdownPrestadorCliente,
        DadosPrestadorDialog,
        TourComponent
    },
    data() {
        return {
            submitted: false,
            form: {
                prestador: null,
                intervaloSugerido: null,
                periodo: null,
                quemAgenda: null,
                quemAvisa: null,
                horarioMarcado: null,
                ...this.formData
            },
            periodosDia: {
                1: 'MANHÃ',
                2: 'TARDE',
                3: 'AMBOS'
            },
            prestador: null,
            showPrestadorDialog: false,
            clientes: [],
            disabledDays: [],
            openingHours: [],
            periodoManhaAtivo: true,
            isNaoAtendePeriodoManha: false,
            isNaoAtendePeriodoTarde: false,
            quemAvisaDisabled: true,
            quemAgendaDisabled: true,
            periodoDisabled: false,
            agendaAvisa: [
                {
                    label: limitarCaracteres(this.formData.profile.name, LIMITE_CARACTERES),
                    value: QuemAgendaAvisa.SOLICITANTE
                },
                {
                    label: limitarCaracteres(this.formData.profile.defaultCompany.name, LIMITE_CARACTERES),
                    value: QuemAgendaAvisa.EMPRESA
                }
            ],
            intervaloMinDate: null,
            intervaloMaxDate: null,
            horarioInvalido: false,
            validandoDatas: false
        };
    },
    validations() {
        return {
            form: {
                prestador: { required: helpers.withMessage('O prestador deve ser informado', required) },
                intervaloSugerido: {
                    required: helpers.withMessage('O intervalo sugerido deve ser informado', required),
                    $each: helpers.forEach({
                        required: helpers.withMessage('O intervalo sugerido deve ser informado', required)
                    })
                },
                periodo: { required: helpers.withMessage('O período deve ser informado', required) },
                quemAgenda: {
                    required: helpers.withMessage('Quem agenda deve ser informado', required),
                    betweenValue: between(QuemAgendaAvisa.SOLICITANTE, QuemAgendaAvisa.EMPRESA)
                },
                quemAvisa: {
                    required: helpers.withMessage('Quem avisa deve ser informado', required),
                    betweenValue: between(QuemAgendaAvisa.SOLICITANTE, QuemAgendaAvisa.EMPRESA)
                },
                horarioMarcado: {
                    required: helpers.withMessage(
                        'O horário deve ser informado',
                        requiredIf(() => this.exibirCampoHorario)
                    )
                }
            }
        };
    },
    mounted() {
        this.$clienteMetodoAtendimentoService = new BaseService('cliente-metodo-atendimento/find-by-exame-cliente/:idTipo');
        this.$servicePrestadores = new BaseService('/suppliers');
        this.intervaloMinDate = moment().toDate();
        setTimeout(() => {
            this.form.periodo = EnumPeriodo.AMBOS;
        }, 0);
    },
    async activated() {
        this.form = { ...this.form, ...this.formData };

        this.clientes = uniq(this.form.funcionarios.map((p) => p.customerId));

        if (!this.form.quemAgenda || !this.form.quemAvisa) {
            await this.defineQuemAgendaAvisa();
        }
    },
    computed: {
        prestadorUsaSoc() {
            return this.form.prestador && this.form.prestador.systemsAndTechnology?.name === 'SOC';
        },
        periodos() {
            const periodo = [
                { label: 'Manhã', value: EnumPeriodo.MANHA, visible: this.periodoManhaAtivo && !this.isNaoAtendePeriodoManha },
                { label: 'Tarde', value: EnumPeriodo.TARDE, visible: !this.isNaoAtendePeriodoTarde },
                {
                    label: 'Ambos',
                    value: EnumPeriodo.AMBOS,
                    visible: this.periodoManhaAtivo && !this.prestadorUsaSoc && !this.isNaoAtendePeriodoTarde && !this.isNaoAtendePeriodoManha
                }
            ];
            return periodo.filter((p) => p.visible === true);
        },
        filtrosExtrasPrestador() {
            const tiposDeExamesQuePrecisamDeClinico = [
                TipoExame.ADMISSIONAL,
                TipoExame.PERIODICO,
                TipoExame.DEMISSIONAL,
                TipoExame.RETORNO_AO_TRABALHO,
                TipoExame.MUDANCA_FUNCAO
            ];
            const apenasQueAtendamClinico = tiposDeExamesQuePrecisamDeClinico.includes(this.formData.tipoExame?.id);
            return {
                apenasQueAtendamClinico,
                agruparPrestadores: true
            };
        },
        exibirCampoHorario() {
            return (
                !this.prestadorUsaSoc &&
                this.form.quemAgenda === QuemAgendaAvisa.SOLICITANTE &&
                this.form.periodo &&
                this.form.intervaloSugerido?.[0] &&
                this.form.intervaloSugerido?.[1]
            );
        },
        metodoAgendamentoPadraoCliente() {
            return !this.formData.profile.quemAgenda && !this.formData.profile.quemAvisa;
        }
    },
    watch: {
        async 'form.prestador'(value) {
            this.form.intervaloSugerido = null;
            this.form.periodo = null;

            await this.carregarHorariosAtendimentoPrestador();
            this.desabilitarDiasNaoAtendidos(value?.id);
            await this.defineQuemAgendaAvisa();
            this.validarSePrestadorAtendePeriodoEHorario();
        },
        'form.intervaloSugerido'() {
            this.form.periodo = null;
            this.isNaoAtendePeriodoManha = false;
            this.isNaoAtendePeriodoTarde = false;

            this.validarSePrestadorAtendePeriodoEHorario();
            this.validarDisponibilidadePeriodo();
        },
        'form.periodo'() {
            this.validarSePrestadorAtendePeriodoEHorario();
        },
        'form.quemAgenda'() {
            const dataInicial = this.form.intervaloSugerido?.[0];
            const empresaAgenda = this.form.quemAgenda === QuemAgendaAvisa.EMPRESA;
            const dataInicialEHoje = dataInicial && moment().isSame(moment(dataInicial), 'days');

            if (empresaAgenda) {
                this.intervaloMinDate = moment().add(1, 'day').toDate();
                if (dataInicialEHoje) this.form.intervaloSugerido = [];
            } else this.intervaloMinDate = moment().toDate();

            this.validarSePrestadorAtendePeriodoEHorario();
        }
    },
    methods: {
        prevPage() {
            this.$emit('prev-page', { formData: this.form, pageIndex: 1 });
        },
        async nextPage() {
            this.submitted = true;
            await this.$refs.inputCalendario.verificarDatas();

            if (!(await this.v$.form.$validate())) {
                return;
            }

            if (!(await this.validarSePrestadorAtendePeriodoEHorario())) {
                return;
            }

            const validacaoData = this.validarDisponibilidadeData();
            if (validacaoData) {
                showWarning(this.$toast, validacaoData);
                return;
            }

            this.$emit('next-page', { formData: this.form, pageIndex: 1 });
        },
        showInformacoesPrestador(prestador) {
            this.prestador = prestador;
            this.showPrestadorDialog = !this.showPrestadorDialog;
        },
        setPeriodoManhaVisible(visible) {
            this.periodoManhaAtivo = visible;
            if (!this.periodoManhaAtivo && this.form.periodo === 1) {
                this.form.periodo = null;
            }
        },
        desabilitarDiasNaoAtendidos(prestadorId) {
            if (!prestadorId || !this.openingHours.length) return;

            this.disabledDays = [];
            this.intervaloMaxDate = null;

            let currentWeekDay = null;
            if (this.form.intervaloSugerido && this.form.intervaloSugerido[0]) {
                currentWeekDay = moment(this.form.intervaloSugerido[0]).weekday();
            }

            this.$primevue.config.locale.dayNamesShort.forEach((value, index) => {
                const horario = this.openingHours.find((element) => element.weekDay.toLowerCase() === value);
                if (horario && (horario.atendimentoMatutino || horario.atendimentoVespertino)) {
                    if (currentWeekDay && currentWeekDay + 1 === index) {
                        currentWeekDay = index;
                    }
                    return;
                }
                this.disabledDays.push(index);
            });

            if (currentWeekDay) {
                const days = currentWeekDay - moment(this.form.intervaloSugerido[0]).weekday();
                this.intervaloMaxDate = moment(this.form.intervaloSugerido[0], 'YYYY-MM-DD').add(days, 'd').toDate();
            }
        },
        async defineQuemAgendaAvisa() {
            if (!this.form.prestador) return;
            if (this.prestadorUsaSoc && this.formData?.cliente?.usaReservaHorario) {
                this.form.quemAvisa = QuemAgendaAvisa.SOLICITANTE;
                this.form.quemAgenda = QuemAgendaAvisa.SOLICITANTE;
                this.quemAgendaDisabled = true;
                this.quemAvisaDisabled = !this.formData.cliente.permiteAlterarMetodoReservaHorario;
            } else {
                if (this.metodoAgendamentoPadraoCliente) {
                    await this.defineClienteQuemAgendaAvisa();
                } else {
                    this.form.quemAvisa = this.formData.profile.quemAvisa;
                    this.form.quemAgenda = this.formData.profile.quemAgenda;
                }

                this.quemAvisaDisabled = this.form.quemAvisa !== QuemAgendaAvisa.USUARIO_ESCOLHE;
                this.quemAgendaDisabled = this.form.quemAgenda !== QuemAgendaAvisa.USUARIO_ESCOLHE;
            }
        },
        async defineClienteQuemAgendaAvisa() {
            this.$clienteMetodoAtendimentoService.path = this.$clienteMetodoAtendimentoService.path.replace(':idTipo', this.formData.tipoExame.id);

            const { data } = await this.$clienteMetodoAtendimentoService.findById(this.formData.cliente.id);

            if (data) {
                this.form.quemAvisa = data.quemAvisa;
                this.form.quemAgenda = data.quemAgenda;
            } else {
                this.form.quemAvisa = this.formData.cliente.quemAvisa;
                this.form.quemAgenda = this.formData.cliente.quemAgenda;
            }
        },
        async validarSePrestadorAtendePeriodoEHorario() {
            this.validandoDatas = true;
            const quemAgenda = this.form.quemAgenda && this.form.quemAgenda !== QuemAgendaAvisa.USUARIO_ESCOLHE;
            const intervaloSugerido = this.form.intervaloSugerido?.[0] && this.form.intervaloSugerido?.[1];
            if (!this.form.prestador || !intervaloSugerido || !this.form.periodo || !quemAgenda) {
                this.validandoDatas = false;
                return false;
            }

            const horario = this.form.horarioMarcado;
            this.horarioInvalido = !horario || !moment(horario, 'HH:mm').isValid();
            if (this.exibirCampoHorario && this.horarioInvalido) {
                this.validandoDatas = false;
                return false;
            }

            const periodoDaManha = [EnumPeriodo.MANHA, EnumPeriodo.AMBOS].includes(this.form.periodo);
            const periodoDaTarde = [EnumPeriodo.TARDE, EnumPeriodo.AMBOS].includes(this.form.periodo);
            const horarioEValidoDeManha = periodoDaManha && horario <= '12:00';
            const horarioEValidoDeTarde = periodoDaTarde && horario > '12:00';

            if (this.exibirCampoHorario && !horarioEValidoDeManha && !horarioEValidoDeTarde) {
                showWarning(this.$toast, 'O horário informado não está no período selecionado');
                this.$nextTick(() => (this.form.horarioMarcado = ''));
                this.validandoDatas = false;
                return false;
            }

            const horarioAtualEMaior = moment().format('HH:mm') > horario;
            const dataFimEHoje = moment(this.form.intervaloSugerido[1]).isSame(moment(), 'day');
            const dataInicioEHoje = moment(this.form.intervaloSugerido[0]).isSame(moment(), 'day');
            if (this.exibirCampoHorario && dataInicioEHoje && dataFimEHoje && horarioAtualEMaior) {
                showWarning(this.$toast, 'O horário informado é menor que o atual');
                this.$nextTick(() => (this.form.horarioMarcado = ''));
                this.validandoDatas = false;
                return false;
            }

            const dataFim = moment(this.form.intervaloSugerido[1]);
            const dataInicio = moment(this.form.intervaloSugerido[0]);

            let horarioInformadoEValido = false;
            while (dataInicio.isSameOrBefore(dataFim, 'day')) {
                const diaDaSemana = obterDiaDaSemanaPorIndice(dataInicio.day());
                const horarioDeAtedimento = this.openingHours.find((p) => p.weekDay === diaDaSemana);

                if (horarioDeAtedimento) {
                    const horarioEValidoManha = horario >= horarioDeAtedimento.startHourAm && horario <= horarioDeAtedimento.endHourAm;
                    const horarioEValidoTarde = horario >= horarioDeAtedimento.startHourPm && horario <= horarioDeAtedimento.endHourPm;
                    const atendeHorarioDeManha = periodoDaManha && horarioEValidoManha;
                    const atendeHorarioDeTarde = periodoDaTarde && horarioEValidoTarde;
                    if (atendeHorarioDeManha || atendeHorarioDeTarde) {
                        horarioInformadoEValido = true;
                    }
                }
                dataInicio.add(1, 'day');
            }

            if (this.exibirCampoHorario && !horarioInformadoEValido) {
                showWarning(this.$toast, 'O prestador não atende neste horário dentro do intervalo sugerido');
                this.$nextTick(() => (this.form.horarioMarcado = ''));
                this.validandoDatas = false;
                return false;
            }
            this.validandoDatas = false;
            return true;
        },

        validarDisponibilidadePeriodo() {
            if (!this.form.prestador || !this.form?.intervaloSugerido?.[0] || !this.form?.intervaloSugerido?.[1]) {
                return;
            }

            let dataInicial = moment(this.form.intervaloSugerido[0]);
            const dataFinal = moment(this.form.intervaloSugerido[1]);
            const totalDias = dataFinal.diff(dataInicial, 'days') + 1;
            const dias = Array.from({ length: totalDias }, (_, index) => moment(dataInicial).add(index, 'days'));

            const atendimentosMatutinos = dias.map((dia) => {
                const diaDaSemana = obterDiaDaSemanaPorIndice(dia.day());
                const horarioDeAtendimento = this.openingHours.find((p) => p.weekDay === diaDaSemana);
                return horarioDeAtendimento?.atendimentoMatutino;
            });

            const atendimentosVespertinos = dias.map((dia) => {
                const diaDaSemana = obterDiaDaSemanaPorIndice(dia.day());
                const horarioDeAtendimento = this.openingHours.find((p) => p.weekDay === diaDaSemana);
                return horarioDeAtendimento?.atendimentoVespertino;
            });

            this.isNaoAtendePeriodoManha = atendimentosMatutinos.every((valor) => !valor);
            this.isNaoAtendePeriodoTarde = atendimentosVespertinos.every((valor) => !valor);
        },
        async carregarHorariosAtendimentoPrestador() {
            if (!this.form.prestador) {
                this.openingHours = [];
                return;
            }
            try {   
            const { data } = await this.$servicePrestadores.findById(this.form.prestador.id);
            this.openingHours = data.openingHours; 
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: `Não foi possível buscar agenda de horários do prestador. ${error?.message}`,
                    life: 5000
                })
            }
        },
        validarDisponibilidadeData() {
            const periodoSeguro = this.form.profile.defaultCompany.periodoSeguroParaAgendamento;
            const dataSelecionada = this.form.intervaloSugerido[0];
            const dataComPeriodoDeSeguranca = moment().add(periodoSeguro, 'hours').toDate();

            const dataDeAmanhaFormatada = moment(dataComPeriodoDeSeguranca).add(1, 'days').format('DD/MM/YYYY');

            if (periodoSeguro) {
                if (!dataSelecionada) {
                    return 'Selecione uma data válida';
                }

                if (
                    moment(dataSelecionada).format('DD/MM/YYYY') === moment(dataComPeriodoDeSeguranca).format('DD/MM/YYYY') &&
                    moment(dataComPeriodoDeSeguranca, 'HH:mm', true).hour() <= 12
                ) {
                    return `O período de datas está muito próximo!  Por favor, selecione um intervalo a partir de ${moment(
                        dataComPeriodoDeSeguranca
                    ).format('DD/MM/YYYY')} no período da TARDE.`;
                }

                if (
                    moment(dataSelecionada).format('DD/MM/YYYY') === moment(dataComPeriodoDeSeguranca).format('DD/MM/YYYY') &&
                    moment(dataComPeriodoDeSeguranca, 'HH:mm', true).hour() >= 13
                ) {
                    return `O período de datas está muito próximo!  Por favor, selecione um intervalo a partir de ${dataDeAmanhaFormatada} no período da MANHÃ.`;
                }
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.p-card.no-border {
    box-shadow: unset;
}

.p-card.no-padding > :deep(.p-card-body) {
    padding: 0;
}

a.link-disable {
    pointer-events: none;
    color: gray;
}
</style>
